/* eslint-disable simple-import-sort/imports */
import * as Sentry from "@sentry/react"; // Disable ESLint import sorting because '@sentry' require 'vue' and 'browser' packages to be imported before 'tracking' package
import { Integrations as BrowserIntegrations } from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import LoglevelSentryPlugin, { redactBreadcrumbData } from "@toruslabs/loglevel-sentry";
import log from "loglevel";

import { AUTH_SERVER_URL, BUILD_ENV, SENTRY_SAMPLING_RATE, SENTRY_DSN } from "./constants";

function getSampleRate(): number {
  try {
    return Number.parseFloat(SENTRY_SAMPLING_RATE);
  } catch {
    return 0.2;
  }
}

export function installSentry(): void {
  if (!SENTRY_DSN) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: BUILD_ENV,
    integrations: [
      new BrowserIntegrations.Breadcrumbs({ console: false }),
      new BrowserTracing({
        tracingOrigins: [AUTH_SERVER_URL],
      }),
    ],
    autoSessionTracking: true,
    ignoreErrors: [
      // Happen when user click 'X' on the browser (ref https://forum.sentry.io/t/typeerror-failed-to-fetch-reported-over-and-overe/8447/2)
      "TypeError: Failed to fetch", // All except iOS and Firefox
      "TypeError: cancelled", // iOS
      "TypeError: NetworkError when attempting to fetch resource.", // Firefox
    ],
    sampleRate: getSampleRate(),
    tracesSampleRate: getSampleRate(),
    normalizeDepth: 8,
    beforeBreadcrumb(breadcrumb) {
      // eslint-disable-next-line no-param-reassign
      breadcrumb.data = redactBreadcrumbData(breadcrumb.data);
      return breadcrumb;
    },
  });

  const plugin = new LoglevelSentryPlugin(Sentry);
  plugin.install(log);
}

export function setSentryEnabled(enabled: boolean): void {
  const client = Sentry.getCurrentHub().getClient();
  if (!client) return;

  client.getOptions().enabled = enabled;
}
