import "./style.scss";

import { useTheme } from "@mui/material/styles";

import { LoaderInfo } from "../../utils/interface";

type IBoxLoaderProps = {
  loaderInfo?: LoaderInfo;
};

const Loader = function Loader({ loaderInfo }: IBoxLoaderProps) {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  const isDark = theme.palette.mode === "dark";
  const size = loaderInfo?.size || 60;

  // Spinner styles
  const spinnerStyle = {
    backgroundColor: color,
    background: `conic-gradient(transparent, ${color})`,
    width: `${size}px`,
    height: `${size}px`,
  };
  const headStyle = {
    backgroundColor: color,
    left: `${Math.ceil(size / 2) - 4}px`,
  };
  const maskStyle = { width: `${size - 10}px`, height: `${size - 10}px` };

  // Ping styles
  const pingColor = isDark ? "#fff" : color;
  const pingAnimateStyle = {
    backgroundColor: pingColor,
  };
  const pingContentStyle = { width: `${size}px`, height: `${size}px`, backgroundColor: pingColor };
  const imageSize = size * 0.56;
  const pingImageStyle = { width: `${imageSize}px`, height: `${imageSize}px` };

  return (
    <div>
      {loaderInfo?.useSpinner || !loaderInfo?.dappLogo ? (
        <div className={`spinner ${isDark ? "spinner--dark" : ""}`} style={spinnerStyle}>
          <div className="head" style={headStyle} />
          <div className="mask" style={maskStyle} />
        </div>
      ) : (
        <div className="ping-container">
          <div className="ping-animate" style={pingAnimateStyle} />
          <div className="ping-content" style={pingContentStyle}>
            <img src={loaderInfo?.dappLogo} alt="Dapp Logo" style={pingImageStyle} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Loader;
