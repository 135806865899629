import "./style.scss";

import { Grid } from "@mui/material";
import { Component, ErrorInfo, ReactNode, Suspense } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import Web3AuthLogo from "../../assets/web3auth.svg";
import { LoaderInfo } from "../../utils/interface";
import createLogger from "../../utils/logger";
import Loader from "../Loader/loader";

const log = createLogger("ErrorBoundary.tsx");
interface IErrorBoundaryState {
  hasError: boolean;
}

interface IErrorBoundaryProps extends WithTranslation {
  children: ReactNode;
  loaderInfo?: LoaderInfo;
}

class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  public state: IErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error): IErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    log.error(error);
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // You can also log the error to an error reporting service
    log.error(error, info);
  }

  // eslint-disable-next-line class-methods-use-this
  onButtonClick = () => {
    setTimeout(() => {
      document.location.replace("/");
    }, 2000);
  };

  render() {
    const { hasError } = this.state;
    const { children, t, loaderInfo } = this.props;
    // The click is intended
    // Maybe a loader in suspense
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          minHeight: "100vh",
          backgroundColor: (theme) => theme.palette.background.default,
          padding: "50px 0px",
        }}
      >
        <div className="content">
          {hasError ? (
            <>
              <h1 style={{ color: "#fff" }}>{t("passwordless.error-boundary-title")}</h1>
              <button type="button" style={{ display: "none" }} onClick={() => this.onButtonClick()}>
                {t("passwordless.error-boundary-btn-home")}
              </button>
            </>
          ) : (
            <Suspense fallback={<Loader beforeDoneMessage={`${t("passwordless.loading")}...`} loaderInfo={loaderInfo} />}>{children}</Suspense>
          )}
        </div>
        <div className="footer">
          <div className="powered-by">{t("common.selfCustodial")}</div>
          <img height="26" src={Web3AuthLogo} alt="Web3Auth" />
        </div>
      </Grid>
    );
  }
}

export default withTranslation()(ErrorBoundary);
