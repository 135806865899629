export const AUTH_SERVER_URL = process.env.REACT_APP_AUTH_SERVER_URL as string;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as string;
export const SENTRY_SAMPLING_RATE = process.env.REACT_APP_SENTRY_SAMPLE_RATE || "1";
export const BUILD_ENV = process.env.REACT_APP_BUILD_ENV;
export const PASSWORDLESS = {
  EMAIL: "email",
  SMS: "sms",
} as const;
export const RESEND_PASSWORDLESS_TIMEOUT = 60;
export const LANGUAGE_MAP = {
  en: "english",
  de: "german",
  ja: "japanese",
  ko: "korean",
  zh: "mandarin",
  es: "spanish",
  fr: "french",
  pt: "portuguese",
  nl: "dutch",
} as const;
