import { createTheme, PaletteOptions, SimplePaletteColorOptions, ThemeProvider } from "@mui/material/styles";
import base64url from "base64url";
import i18n from "i18next";
import { parse } from "query-string";
import { lazy, useEffect, useMemo, useState } from "react";
import { initReactI18next } from "react-i18next";
import { useLocation } from "react-router";
import { Route, Routes } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import { en } from "./i18n";
import { LANGUAGE_MAP } from "./utils/constants";
import { IWhiteLabel, LoaderInfo } from "./utils/interface";
import createLogger from "./utils/logger";

const log = createLogger("App.tsx");
const Start = lazy(() => import("./containers/Start"));
const Verify = lazy(() => import("./containers/Verify"));

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: { escapeValue: false },
});

const App = function App() {
  const { search } = useLocation();
  const [theme, setTheme] = useState<PaletteOptions>({
    mode: "light",
    background: {
      default: "#FFFFFF",
    },
    primary: {
      main: "#0364FF",
    },
    text: {
      primary: "#0E182F",
      secondary: "#808080",
    },
    grey: {
      "50": "#F9FAFB",
      "300": "#D1D5DB",
      "700": "#374151",
      "900": "#111928",
    },
    error: {
      main: "#F05252",
    },
    success: {
      main: "#0E9F6E",
      light: "#F3FAF7",
    },
  });

  const stateData = useMemo(() => {
    const { state } = parse(search);
    const finalStateData: {
      whiteLabel?: IWhiteLabel;
      isCustomVerifier?: boolean;
    } = {
      whiteLabel: undefined,
      isCustomVerifier: undefined,
    };

    if (state) {
      const stateParams = JSON.parse(base64url.decode(decodeURIComponent(state as string)));
      if (stateParams.whiteLabel) {
        const whiteLabelData = JSON.parse(stateParams.whiteLabel) as IWhiteLabel;
        finalStateData.whiteLabel = whiteLabelData;
      }
      finalStateData.isCustomVerifier = stateParams?.isCustomVerifier === "true" || false;
    }

    return finalStateData;
  }, [search]);

  useEffect(() => {
    if (stateData.whiteLabel) {
      const isDark = stateData.whiteLabel.dark || false;
      let primaryMain = "#0364FF";

      if (stateData.whiteLabel.theme?.primary) {
        primaryMain =
          typeof stateData.whiteLabel.theme?.primary === "string" ? stateData.whiteLabel.theme?.primary : stateData.whiteLabel.theme?.primary[600];
      }

      const newTheme: PaletteOptions = {
        mode: isDark ? "dark" : "light",
        primary: {
          main: primaryMain ?? "#0364FF",
        },
        background: {
          default: isDark ? "#252529" : "#FFFFFF", // #FFFFFF #252529
        },
        text: {
          primary: isDark ? "#F9F9FB" : "#0E182F", // #0E182F #F9F9FB
          secondary: isDark ? "#D5D5D5" : "#808080", // #282E42 #D5D5D5
        },
        grey: {
          50: (stateData.whiteLabel.theme?.gray && stateData.whiteLabel.theme?.gray[50]) ?? "#F9FAFB",
          300: (stateData.whiteLabel.theme?.gray && stateData.whiteLabel.theme?.gray[300]) ?? "#D1D5DB",
          700: (stateData.whiteLabel.theme?.gray && stateData.whiteLabel.theme?.gray[700]) ?? "#374151",
          900: (stateData.whiteLabel.theme?.gray && stateData.whiteLabel.theme?.gray[900]) ?? "#111928",
        },
        error: {
          main: stateData.whiteLabel.theme?.error ?? "#F05252",
        },
        success: {
          main: stateData.whiteLabel.theme?.success ?? "#0E9F6E",
          light: "#F3FAF7",
        },
      };
      setTheme(newTheme);

      // Load new language resource
      if (
        stateData.whiteLabel.defaultLanguage &&
        stateData.whiteLabel.defaultLanguage !== "en" &&
        Object.keys(LANGUAGE_MAP).includes(stateData.whiteLabel.defaultLanguage)
      ) {
        const newLang = stateData.whiteLabel.defaultLanguage;

        switch (newLang) {
          case "nl":
            import(/* webpackChunkName: "lang-dutch" */ `./i18n/dutch.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "pt":
            import(/* webpackChunkName: "lang-portuguese" */ `./i18n/portuguese.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "fr":
            import(/* webpackChunkName: "lang-french" */ `./i18n/french.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "de":
            import(/* webpackChunkName: "lang-german" */ `./i18n/german.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "ja":
            import(/* webpackChunkName: "lang-japanese" */ `./i18n/japanese.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "ko":
            import(/* webpackChunkName: "lang-korean" */ `./i18n/korean.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "es":
            import(/* webpackChunkName: "lang-spanish" */ `./i18n/spanish.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
          case "zh":
            import(/* webpackChunkName: "lang-mandarin" */ `./i18n/mandarin.json`)
              .then((messages) => {
                i18n.addResourceBundle(stateData.whiteLabel?.defaultLanguage as string, "translation", messages.default);
                return i18n.changeLanguage(stateData.whiteLabel?.defaultLanguage || "en");
              })
              .catch((error) => {
                log.error(error);
              });
            break;
        }
      }
    }
  }, [setTheme, stateData]);

  const activeTheme = createTheme({
    palette: theme,
    typography: {
      fontFamily: "'DM Sans', sans-serif",
      h2: {
        fontSize: "3.75em",
        lineHeight: 1.2,
        color: theme.text?.primary,
      },
      h4: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: "2rem",
        fontWeight: "bold",
        lineHeight: 1.5,
        letterSpacing: "0.002em",
        color: theme.text?.primary,
      },
      h6: {
        fontSize: "1.25em",
        fontWeight: "bold",
        lineHeight: 1.5,
        color: theme.text?.secondary,
      },
      body1: {
        fontWeight: "500",
        color: theme.text?.secondary,
      },
      body2: {
        fontWeight: "500",
        color: (theme.primary as SimplePaletteColorOptions).main,
      },
    },
  });

  // Loader Info
  const hasWhiteLabel = !!stateData.whiteLabel && Object.keys(stateData.whiteLabel).length > 0;
  let whiteLabelLogo = "";
  let useSpinner = true;

  if (hasWhiteLabel) {
    whiteLabelLogo = (stateData.whiteLabel?.dark ? stateData.whiteLabel?.logoDark : stateData.whiteLabel?.logoLight) || "";
    const useLogoLoader = stateData.whiteLabel?.useLogoLoader || false;
    if (whiteLabelLogo && useLogoLoader) {
      useSpinner = false;
    }
  }

  if (useSpinner) {
    useSpinner = !stateData.isCustomVerifier || (stateData.isCustomVerifier && !whiteLabelLogo);
  }

  const loaderInfo: LoaderInfo = {
    dappLogo: whiteLabelLogo,
    useSpinner,
  };

  return (
    <ThemeProvider theme={activeTheme}>
      <ErrorBoundary loaderInfo={loaderInfo}>
        <Routes>
          <Route path="/authorize" element={<Start loaderInfo={loaderInfo} />} />
          <Route path="/verify" element={<Verify loaderInfo={loaderInfo} />} />
          {/* <Redirect to="/" /> */}
        </Routes>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
