import "./style.scss";

import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

import { ReactComponent as LoginIcon } from "../../assets/icons/loggedIn.svg";
import { LoaderInfo } from "../../utils/interface";
import BoxLoader from "../BoxLoader";

type ILoaderProps = {
  isDone?: boolean;
  afterDoneMessage?: string;
  beforeDoneMessage?: string;
  loaderInfo?: LoaderInfo;
};
const defaultProps = {
  isDone: false,
  beforeDoneMessage: "passwordless.loader-logging-in",
  afterDoneMessage: "passwordless.loader-done",
};

const Loader = function Loader({ isDone = false, afterDoneMessage = "", beforeDoneMessage = "", loaderInfo }: ILoaderProps = defaultProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const color = theme.palette.primary.main;

  return (
    <div className="loader-container">
      {isDone ? (
        <LoginIcon color={color} className="done-icon" width={84} height={70} />
      ) : (
        <div style={{ marginBottom: "16px" }}>
          <BoxLoader loaderInfo={loaderInfo} />
        </div>
      )}
      <div>
        <Typography align="center" variant="h4" component="h1" className="mb-4">
          {isDone ? t(afterDoneMessage) : t(beforeDoneMessage)}
        </Typography>
        <Typography align="center" variant="h6">
          {isDone ? t("passwordless.loader-done-note") : t("passwordless.loader-not-done-note")}
        </Typography>
      </div>
    </div>
  );
};

export default Loader;
