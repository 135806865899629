import * as Sentry from "@sentry/react";
import { createLogger } from "@toruslabs/loglevel-sentry";
import log from "loglevel";
import prefix from "loglevel-plugin-prefix";

prefix.reg(log);
log.enableAll();

prefix.apply(log, {
  template: "[%t] %l:",
  levelFormatter(level: string) {
    return level.toUpperCase();
  },
  nameFormatter(name: string | undefined) {
    return name || "root";
  },
  format: undefined,
  timestampFormatter(date: Date) {
    return date.toISOString();
  },
});

const logger = (name: string): log.Logger => createLogger(name, Sentry);
export default logger;
