// This optional code is used to register a service worker.
// register() is not called by default.
import createLogger from "./utils/logger";

const log = createLogger("serviceWorkerRegistration.ts");

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

export function unregister(): void {
  navigator.serviceWorker.ready
    .then((registration) => {
      return registration.unregister();
    })
    .catch((error: Error) => {
      log.warn(error.message);
    });
}

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .getRegistrations()
    .then((x) => x.map((y) => y.unregister()))
    .catch((error) => log.error(error));
}
